import type { ProductItem, Product } from 'src/components/product/types'
import { formatCategoriesForEvent } from 'src/utils/formatted'

import type { Product as ProductEvent } from './types/common/product'

export const makeProductShowcase = (
  product: Product,
  productItem: ProductItem,
  eventOrigin: string
): ProductEvent => {
  const offer = productItem.offerDefault
  const sellerDefault = productItem.sellers.find(
    (seller) => seller.sellerDefault
  )

  const refId = productItem?.referenceId?.find(
    (item) => item.Key === 'RefId'
  )?.Value

  return {
    brand: product.brand.toLocaleLowerCase(),
    category: product.sport,
    dimension1: '', // TODO product.productReference // super modelo sap
    dimension2: refId ?? '', // id sku sap
    dimension12: sellerDefault?.sellerId ?? '',
    dimension13: sellerDefault?.sellerName.toLocaleLowerCase() ?? '',
    dimension21: formatCategoriesForEvent(
      product.categories[0]
    ).toLocaleLowerCase(), // arborescência
    dimension22: productItem.itemId, // id sku vtex
    dimension23: '', // TODO manufacturerCode // id modelo sap (no app é item category 5)
    dimension24: eventOrigin,
    id: product.productId, // id modelo vtex
    name: product.productName.toLocaleLowerCase(),
    price: offer.price,
    quantity: 1,
    variant: `${productItem.itemId} | ${productItem.name}`,
  }
}
