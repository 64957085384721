import { Link } from 'gatsby'
import Logo from 'src/components/ui/Logo'
import { Stripe } from 'src/components/global/Stripe'
import { useGlobalContext } from 'src/components/global/context/global-context'
import { useState } from 'react'
import { UserOutline } from 'src/components/Icons/UserOutline'
import { NewIconHeartOutline } from 'src/components/Icons/NewIconHeartOutline'
import { BagCartOutline } from 'src/components/Icons/BagCartOutline'
import { useCart } from 'src/sdk/cart/useCart'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { redirectToAccountUrl } from 'src/utils/redirectToAccountUrl'

import Menu from './Menu'
import LoginDropdown from './LoginDropdown/LoginDropdown'
import LogoPro from '../../../images/decathlonProLogo.png'
import { SidebarMenu } from './SideBarMenu'
import { SearchInput } from '../SearchInput'
import { ButtonIcon } from './ButtonIcon'

import './header.scss'

function Header() {
  const { stripeSection } = useGlobalContext()
  const { totalUniqueItems } = useCart()
  const btnProps = useCartToggleButton()

  const isB2B = checkEnviromentIsB2B()

  const [isLoginDropdownActive, setIsLoginDropdownActive] = useState(false)

  function handleMouseEnter() {
    setIsLoginDropdownActive(true)
  }

  function handleMouseLeave() {
    setIsLoginDropdownActive(false)
  }

  return (
    <>
      <Stripe stripeSection={stripeSection} />

      <header className="contents">
        <div className="border-b header-container border-restructure-border-secondary bg-restructure-background-primary pt-sm pb-md restructure-small-desktop:pt-lg restructure-small-desktop:pb-0">
          <div className=" max-w-[1280px] w-full mx-auto px-md flex flex-col justify-between relative">
            <div className="flex flex-wrap items-center justify-between">
              <div className="restructure-small-desktop:hidden">
                <SidebarMenu />
              </div>
              {isB2B && (
                <Link
                  to="/"
                  className="w-[200px] restructure-small-desktop:w-[250px] flex items-center h-[24px] restructure-small-desktop:static restructure-small-desktop:top-0 restructure-small-desktop:left-0 restructure-small-desktop:translate-x-[0] absolute top-[7px] left-[50%] translate-x-[-50%]"
                  aria-label="Go to Decathlon home"
                  title="Go to Decathlon home"
                >
                  <img
                    className="w-full object-contain"
                    src={LogoPro}
                    alt="decathlon pro logo"
                  />
                </Link>
              )}

              {!isB2B && (
                <Link
                  to="/"
                  className="flex items-center h-[1.625rem] restructure-small-desktop:static restructure-small-desktop:top-0 restructure-small-desktop:left-0 restructure-small-desktop:translate-x-[0] absolute top-[7px] left-[50%] translate-x-[-50%]"
                  aria-label="Go to Decathlon home"
                  title="Go to Decathlon home"
                >
                  <Logo width={186} height={28} bgFill="#3643BA" />
                </Link>
              )}

              <div className="order-4 mt-sm restructure-small-desktop:mt-0 restructure-small-desktop:order-1 w-full restructure-small-desktop:max-w-[623px]">
                <SearchInput />
              </div>

              <ul className="relative items-center order-2  restructure-small-desktop:flex">
                {!isB2B && (
                  <li className="hidden restructure-small-desktop:flex">
                    <ButtonIcon link="/account/wishlist" badgeValue={0}>
                      <NewIconHeartOutline
                        aria-label="Botao para redirecionar para a wishlist"
                        data-testid="wish-list-button-icon"
                        className="w-6 h-6 restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
                      />
                    </ButtonIcon>
                  </li>
                )}

                <li
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={() => handleMouseLeave()}
                  className="hidden restructure-small-desktop:flex"
                >
                  {isLoginDropdownActive && !isB2B && (
                    <LoginDropdown className="absolute top-full shadow-dropCard left-1/2 bg-white rounded-lg min-w-[202px] -translate-x-1/2  z-10" />
                  )}
                  <ButtonIcon link={redirectToAccountUrl(isB2B)} badgeValue={0}>
                    <UserOutline
                      aria-label="Botão para redirecionar para a pagina de my-account"
                      data-testid="user-icon-button"
                    />
                  </ButtonIcon>
                </li>

                <li>
                  <ButtonIcon
                    link=""
                    badgeValue={totalUniqueItems}
                    {...btnProps}
                  >
                    <BagCartOutline
                      aria-label="botão abrir mini cart"
                      className="w-6 h-6 restructure-small-desktop:w-lg restructure-small-desktop:h-lg"
                    />
                  </ButtonIcon>
                </li>
              </ul>
            </div>
            <Menu />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
