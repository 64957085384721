import { Link } from 'gatsby'
import { LogOutIcon } from 'src/components/Icons/LogOutIcon'
import type { EventTrackParams } from 'src/utils/restructure/analytics/types/eventTrack'

type LogOutProps = {
  className?: string
  trackEvent?: ({ eventAction, eventPage }: EventTrackParams) => void
  location?: string
}

const LogOut = ({ className = '', trackEvent, location }: LogOutProps) => {
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (!trackEvent) return
    trackEvent({
      eventAction: event.currentTarget.ariaLabel!,
      eventPage: location ?? '',
    })
  }

  return (
    <div className={className}>
      <Link
        className="flex items-center gap-2"
        to="/account/logout"
        area-aria-label="Fazer logout"
        onClick={(event) => handleClick(event)}
      >
        <LogOutIcon />
        <span className="font-inter font-normal hover:font-semibold">Sair</span>
      </Link>
    </div>
  )
}

export default LogOut
