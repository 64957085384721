import { useState } from 'react'
import Section from 'src/components/sections/Section/Section'
import { readCookie, setCookie } from 'src/utils/getCookie'

const PopUpCookies = () => {
  const [showPopup, setShowPopup] = useState<boolean>()

  const handleClickPopup = () => {
    setCookie('cookiePolicies', 'true', 365)
    setShowPopup(true)
  }

  return (
    <Section
      className={`bottom-6 popup-cookies hidden ${
        readCookie('cookiePolicies=true') ? '!hidden' : 'fixed z-[99999]'
      }`}
    >
      <div className="layout__content">
        <div
          className={`max-w-md flex flex-col p-4 justify-end gap-6 bg-white ${showPopup} rounded-lg`}
        >
          <div className="popup-cookies__text flex flex-col justify-center">
            <span
              role="img"
              aria-label="cookies"
              className="text-base font-bold"
            >
              🍪 Política de cookies
            </span>
            <p className="text-base pt-2">
              Os cookies nos ajudam a melhorar sua experiência online. Ao
              continuar navegando, você concorda com o uso de cookies. Saiba
              mais na nossa{' '}
              <a
                className="text-restructure-action"
                target="_blank"
                href="https://www.decathlon.com.br/servicos/politica-de-privacidade/"
                rel="noreferrer"
              >
                Política de privacidade
              </a>
              .
            </p>
            <button
              className="w-36 px-6 py-3.5 mt-6 bg-restructure-background-action-1 hover:bg-restructure-background-action-1-hover text-white rounded"
              onClick={handleClickPopup}
            >
              Aceitar
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PopUpCookies
