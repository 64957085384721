import type { Product } from 'src/components/product/types'

export function transformProductSuggestions(products: Product[]) {
  return products.map((product) => ({
    clickUrl: product.clickUrl ?? '',
    imageUrl: product.items[0].images[0].imageUrl,
    name: product.productName,
    offer: product.items[0].offerDefault,
    productId: product.productId,
    slug: product.link,
  }))
}
