import { useEffect, useState } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { maskCurrency } from 'src/utils/convertCurrency'
import { HideBalanceDotsIcon } from 'src/components/Icons/HideBalanceDots'

type CashBackDataProps = {
  userDataCookie: string
  borderRadius?: string
}

type CashbackData = {
  data: {
    balance: number
  }
}

type DecodedToken = {
  access_token: string
  exp: number
  iat: number
}
const CashbackBalance = ({
  userDataCookie,
  borderRadius = 'rounded-t-lg',
}: CashBackDataProps) => {
  const [cashBackBalance, setCashBackBalance] = useState(0)

  useEffect(() => {
    const showDecryptedToken = () => {
      const encryptedToken = userDataCookie
      const decoded: DecodedToken = jwt_decode(encryptedToken)

      return decoded?.access_token
    }

    const fetchData = async () => {
      try {
        const { data }: CashbackData = await axios.post(
          '/api/getCashbackBalance',
          { accesstoken: showDecryptedToken() },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          }
        )

        setCashBackBalance(data.balance)
      } catch (error) {
        console.error(error)
      }
    }

    if (userDataCookie) {
      fetchData()
    }
  }, [userDataCookie])

  return (
    <div
      className={`${borderRadius} px-6 py-4 w-full bg-restructure-background-action-1 font-inter text-white`}
    >
      <span className="text-xs">Cashback disponível</span>

      <div className="text-xl">
        <span className="font-inter">
          {userDataCookie ? (
            maskCurrency(cashBackBalance)
          ) : (
            <div className="flex gap-2 items-center">
              <span className="font-inter">R$</span>
              <HideBalanceDotsIcon />
            </div>
          )}
        </span>
      </div>
    </div>
  )
}

export default CashbackBalance
