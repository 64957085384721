import './src/styles/fonts.css'
import './src/styles/vendors/tailwind.scss'
import './src/styles/vendors/accessible-nprogress.scss'
import './src/styles/global/tokens.scss'
import './src/styles/global/typography.scss'
import './src/styles/global/layout.scss'

import { HelmetProvider } from 'react-helmet-async'
import { StatsigProvider } from 'statsig-react'
import { CartProvider, SessionProvider, UIProvider } from '@faststore/sdk'
import type { GatsbyBrowser } from 'gatsby'
import { ModalProvider } from 'src/sdk/ui/modal'
import Iframe from 'src/Iframe'
// import LogRocket from 'logrocket'
// import setupLogRocketReact from 'logrocket-react'
import AnalyticsHandler from 'src/sdk/analytics'
import { statsigUser } from 'src/sdk/statsig'
import { getStoreConfig } from 'src/store-config/get-config'

import {
  makeVirtualPageEvent,
  sendEvent,
} from './src/utils/restructure/analytics'
import Layout from './src/Layout'
import { validateCart } from './src/sdk/cart/validate'
import ErrorBoundary from './src/sdk/error/ErrorBoundary'
import TestProvider from './src/sdk/tests'
import { uiActions, uiEffects, uiInitialState } from './src/sdk/ui'

const { salesChannel } = getStoreConfig()

interface OnRouteUpdateProps {
  location: {
    pathname: string
    search: string
    hash: string
  }
  prevLocation: {
    pathname: string
    href: string
    origin: string
  }
}

// export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
//   if (
//     process.env.NODE_ENV === 'production' ||
//     process.env.NODE_ENV === 'build'
//   ) {
//     LogRocket.init(storeConfig.logRocket.appId)
//     setupLogRocketReact(LogRocket)
//   }

//   return null
// }

export const onRouteUpdate = ({
  location,
  prevLocation,
}: OnRouteUpdateProps) => {
  if (window) {
    const event = makeVirtualPageEvent(window, prevLocation)

    sendEvent(event)
  }

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'build'
  ) {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    setTimeout(() => {
      if (typeof gtag === 'function') {
        gtag('event', 'page_view', { page_path: pagePath })
      }
    }, 100)
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <AnalyticsHandler />

        <TestProvider>
          <UIProvider
            initialState={uiInitialState}
            actions={uiActions}
            effects={uiEffects}
          >
            <SessionProvider
              initialState={{
                channel: `salesChannel:${salesChannel}`,
                country: 'BRA',
                currency: { code: 'BRL', symbol: 'R$' },
              }}
            >
              <CartProvider mode="optimistic" onValidateCart={validateCart}>
                <ModalProvider>
                  <StatsigProvider
                    user={{ userID: statsigUser.id }}
                    sdkKey="client-H2CHANLHAQjJyKY81gja6XKpH2evNrXZdZJ6md28mwj"
                    waitForInitialization
                    options={{
                      environment: { tier: 'production' },
                    }}
                  >
                    {element}
                  </StatsigProvider>
                </ModalProvider>
              </CartProvider>
            </SessionProvider>
          </UIProvider>
        </TestProvider>
      </ErrorBoundary>
    </HelmetProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  let render = <Layout location={props.location}>{element}</Layout>

  if (
    window.location.pathname === '/header/' ||
    window.location.pathname === '/header-login/'
  ) {
    render = <Iframe>{element}</Iframe>
  }

  return render
}
