import { getCookie } from 'src/utils/getCookie'
import SideMenu from 'src/components/account/SideMenu'

import CashbackBalance from '../../CashbackBalance'
import { TitleSubmenu } from '../HeaderMobile/components/TitleSubmenu'
import LogOut from '../LoginDropdown/LogOut'

type MyAccountSubMenuProps = {
  isMyAccountSubMenuOpen: boolean
  handleMyAccountSubMenu: (state: boolean) => void
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MyAccountSubMenu = ({
  isMyAccountSubMenuOpen,
  setMenuIsOpen,
  handleMyAccountSubMenu,
}: MyAccountSubMenuProps) => {
  const userDataCookie = getCookie('memberAuthToken')

  return (
    <div
      className={`nav-content flex flex-col fixed justify-between h-full top-0 left-0 w-full z-[1] bg-white ${
        isMyAccountSubMenuOpen ? `entrance-animation ` : `exit-animation`
      }`}
    >
      <div>
        <TitleSubmenu
          setMenuIsOpen={setMenuIsOpen}
          actionClose={() => handleMyAccountSubMenu(false)}
          title="Minha Conta"
        />
        <div className="px-4">
          <CashbackBalance
            borderRadius="rounded-lg"
            userDataCookie={userDataCookie}
          />
        </div>
      </div>

      <div className="flex px-4 flex-1 mt-6">
        <SideMenu onCloseModal={setMenuIsOpen} />
      </div>

      <div className="h-[88px] flex items-center bg-neutral02">
        <LogOut className=" ml-4 text-base" />
      </div>
    </div>
  )
}

export default MyAccountSubMenu
