import type { EventTrack, EventTrackParams } from './types/eventTrack'

export const makeEventTrack = ({
  eventAction,
  eventPage,
}: EventTrackParams): EventTrack => {
  return {
    event: 'event_track',
    event_category: 'clique',
    event_action: eventAction,
    event_label: eventPage,
  }
}
