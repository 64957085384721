import { lazy, Suspense, useEffect } from 'react'
import type { ReactElement } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet-async'
import { useUI } from 'src/sdk/ui'
import Header from 'src/components/common/Header'
import { useMobile } from 'src/hooks/useMobile'
import { CepInformationProvider } from 'src/components/common/CepComponent/hooks/CepInformationContext'

import { usePageViewEvent } from './utils/gtmEvents'
import { UserDataProvider } from './components/contexts/UserDataContext'
import PopUpAppsflyer from './components/common/PopUpAppsflyer'
import { ThemeProvider } from './components/contexts/ThemeContext'
import { useLinxOtherView } from './sdk/linx/hooks/useLinxOtherView'
import PopUpCookies from './components/common/PopUpCookies'
import { GlobalProvider } from './components/global/context/global-context'
import Footer from './components/restructure/common/Footer'
import { AccountProvider } from './components/account/context'
import { checkEnviromentIsB2B } from './utils/checkEnviroment'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))

type LayoutProps = {
  children: ReactElement
  location: {
    pathname: string
  }
}

function Layout({ children, location }: LayoutProps) {
  const isB2B = checkEnviromentIsB2B()
  const { displayMinicart } = useUI()
  const { screenWidth } = useMobile()
  const { sendOtherViewEvent } = useLinxOtherView()
  const [mode] = useQueryParam('mode', StringParam)

  const hideHeader = ['member', 'checkout', 'login'].some((item) =>
    location?.pathname.includes(item)
  )

  usePageViewEvent()

  useEffect(() => {
    if (!children) {
      return
    }

    const pathPage = (children as ReactElement)?.props?.children?.[1]?.key

    sendOtherViewEvent(pathPage)
  }, [sendOtherViewEvent, children])

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://decathlonstore.vteximg.com.br/arquivos/banner-decathlon-share.png?v=637511853294570000"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <UserDataProvider>
        <ThemeProvider>
          <CepInformationProvider>
            <GlobalProvider>
              <AccountProvider>
                {mode !== 'app' && !hideHeader && <Header />}

                <>{children}</>

                {mode !== 'app' && !hideHeader && <Footer />}

                {screenWidth <= 1024 && isB2B === false && <PopUpAppsflyer />}

                <PopUpCookies />

                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-T777CVM"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                    title="Google Tag Manager iframe"
                  />
                </noscript>

                {displayMinicart && (
                  <Suspense fallback={null}>
                    <CartSidebar />
                  </Suspense>
                )}
              </AccountProvider>
            </GlobalProvider>
          </CepInformationProvider>
        </ThemeProvider>
      </UserDataProvider>
    </>
  )
}

export default Layout
