import Button from 'src/components/ui/Button'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import { useCart } from '@faststore/sdk'
import Logo from 'src/components/ui/Logo'
import Icon from 'src/components/ui/Icon'

import { SearchInput } from '../SearchInput'

const actions = [
  {
    title: 'NOSSAS LOJAS',
    image: 'OurStore',
    link: '/lojas',
  },
  {
    title: 'ATENDIMENTO',
    image: 'CallCenter',
    link: '/atendimento',
  },
]

function HeaderIframe() {
  const btnProps = useCartToggleButton()
  const { items } = useCart()

  return (
    <header className=" bg-blue fixed w-full top-0 py-3 md:w-full md:py-0 z-10">
      <div className="md:flex md:justify-between md:flex-col-1 grid grid-cols-1 items-center mx-auto">
        <div className="grid grid-rows-1 md:flex md:flex-col-1">
          <div className="flex justify-between px-4 md:px-0 w-full md:w-auto">
            <a
              target="_parent"
              href="/"
              className="flex items-center"
              aria-label="Home"
            >
              <Logo />
            </a>

            <Button
              className="hidden relative"
              aria-label="abrir e fechar carrinho"
              {...btnProps}
            >
              <span className="absolute top-0 p-[10px] right-0 bg-vivid-yellow rounded-full text-blue text-xs font-bold w-4 h-4 flex items-center justify-center">
                {items.length}
              </span>
              <Icon name="Cart" width={24} height={24} />
            </Button>
          </div>
        </div>
        <div
          data-store-header-search
          className="searchInput flex-1 inline-grid px-4 md:m-0 mt-2.5 hidden-mobile"
        >
          <SearchInput />
        </div>

        <div className="flex md:max-w-[420px] hidden-mobile">
          {actions.map((item, index) => (
            <a
              key={index}
              target="_parent"
              href={item.link}
              className="p-3 flex flex-col items-center"
            >
              <Icon name={item.image} width={24} height={24} />
              <p className="text-white mt-2 font-bold text-sm font-fallback whitespace-nowrap">
                {item.title}
              </p>
            </a>
          ))}

          <div className="text-center md:bg-darkBlue">
            <Button aria-label="abrir e fechar carrinho" {...btnProps}>
              <div className="relative flex justify-center">
                <span className="absolute top-[-9px] p-[10px] right-[7px] bg-vivid-yellow rounded-full text-blue text-xs font-bold w-4 h-4 flex items-center justify-center">
                  {items.length}
                </span>
                <Icon name="Cart" width={24} height={24} />
              </div>
              <p className="font-bold text-white mt-2 md:flex text-sm font-fallback whitespace-nowrap">
                CARRINHO
              </p>
            </Button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderIframe
