import { Splide, SplideSlide } from '@splidejs/react-splide'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useMobile } from 'src/hooks/useMobile'
import type {
  StripeItem,
  StripeSectionProps,
} from 'src/components/global/types'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

export const Stripe = ({ stripeSection }: StripeSectionProps) => {
  const { isMobile } = useMobile()

  if (!stripeSection || !stripeSection.enableSession) {
    return null
  }

  const isB2B = checkEnviromentIsB2B()

  return (
    <div
      data-testid="section-stripe"
      className="flex justify-center bg-restructure-background-primary-inverted text-restructure-primary-inverted"
    >
      {stripeSection.stripes?.length > 1 ? (
        <Splide
          options={{
            perPage: 1,
            arrows: false,
            pagination: false,
            type: stripeSection.stripes?.length > 1 ? 'loop' : 'slide',
            direction: 'ttb',
            height: 40,
            cover: false,
            rewind: false,
            autoplay: true,
            interval: 4000,
            breakpoints: {
              900: {
                height: 32,
              },
            },
          }}
        >
          {stripeSection.stripes?.map((stripe: StripeItem) => (
            <SplideSlide
              key={stripe.stripeText}
              className="flex items-center justify-center"
            >
              <span
                data-testid="stripe-item"
                className="mobile-caption-regular desktop-body-regular-text3"
              >
                {stripe.stripeText}
              </span>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <div
          data-testid="section-stripe"
          className="flex items-center h-[32px]"
        >
          <span
            data-testid="stripe-item"
            className="mobile-caption-regular desktop-body-regular-text3"
          >
            {isB2B ? (
              <div>
                <a
                  className="flex items-center"
                  href={stripeSection.stripes?.[0].link}
                >
                  {stripeSection.stripes?.[0].stripeText}

                  <img
                    alt="Icon"
                    className="ml-[3px]"
                    src={stripeSection.stripes?.[0].icon}
                    width={isMobile ? '16' : '24'}
                    height={isMobile ? '16' : '24'}
                  />
                </a>
              </div>
            ) : (
              stripeSection.stripes?.[0].stripeText
            )}
          </span>
        </div>
      )}
    </div>
  )
}
