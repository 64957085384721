import { createContext, useMemo } from 'react'
import type { PropsWithChildren } from 'react'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

// @todo .env set tenant
const defaultTenant = 'B2B'

type TenantContextValue = {
  tenant: 'B2C' | 'B2B'
}

export const TenantContext = createContext<TenantContextValue>({
  tenant: defaultTenant,
})

export function TenantProvider({ children }: PropsWithChildren) {
  const { tenant } = useStoreConfig() as { tenant: 'B2B' | 'B2C' }

  const value = useMemo(() => ({ tenant }), [tenant])

  return (
    <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
  )
}
