import type { ProductItem, Product } from 'src/components/product/types'

import { makeProductShowcase } from './makeProductShowcase'
import type { ProductClickEvent } from './types/productClickEvent'

export const makeProductClickEvent = (
  product: Product,
  productItem: ProductItem,
  actionField: string
): ProductClickEvent => {
  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: actionField },
        products: [makeProductShowcase(product, productItem, 'NA')],
      },
      eventCallback: () => {
        document.location = `https://www.decathlon.com.br/${product.linkText}/p`
      },
    },
  }
}
