import { getCookie } from 'src/utils/getCookie'
import { useLocation } from '@reach/router'
import { makeEventTrack } from 'src/utils/restructure/analytics'

import CashbackBalance from '../../CashbackBalance/CashbackBalance'
import LoggedInContent from './LoggedInContent'
import LoggedOutContent from './LoggedOutContent'

type LoginDropdownProps = {
  className?: string
}

const LoginDropdown = ({ className = '' }: LoginDropdownProps) => {
  const userDataCookie = getCookie('memberAuthToken')
  const location = useLocation()

  return (
    <div className={className}>
      <CashbackBalance userDataCookie={userDataCookie} />

      <div className="px-6 py-4">
        {userDataCookie ? (
          <LoggedInContent
            trackEvent={makeEventTrack}
            location={location.pathname}
          />
        ) : (
          <LoggedOutContent
            trackEvent={makeEventTrack}
            location={location.pathname}
          />
        )}
      </div>
    </div>
  )
}

export default LoginDropdown
