import type {
  ProductItem,
  Product,
} from 'src/components/restructure/product/sections/ShelfBoughtTogether/utils/types'

import { makeProductShowcase } from './makeProductShowcase'
import type { ViewListImpressionEvent } from './types/viewListImpressionEvent'

export const makeViewListImpressionEvent = (
  items: Array<{ product: Product; productItem: ProductItem }>,
  complements: {
    actionField?: { list: string }
    eventOrigin: string
  }
): ViewListImpressionEvent => {
  const { actionField, eventOrigin } = complements

  return {
    event: 'viewListImpression',
    ecommerce: {
      currencyCode: 'BRL',
      ...(actionField && { actionField }),
      viewListImpression: items.map((item) =>
        makeProductShowcase(item.product, item.productItem, eventOrigin)
      ),
    },
  }
}
