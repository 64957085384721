import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import type { SocialData } from 'src/components/global/types'

import { useFooter } from './useFooter'

type SocialMediaProps = {
  socialLinks: SocialData[]
}

export const SocialMedia = ({ socialLinks }: SocialMediaProps) => {
  const { sendEventRedirect } = useFooter()

  return (
    <div className="flex flex-row gap-4 min-w-[32px] max-w-[210px] flex-wrap">
      {socialLinks.map((socialLink) => {
        const { image, link, text } = socialLink

        return (
          <Link
            key={link}
            target="_blank"
            to={socialLink.link}
            data-testid={`footerSocialMedia${text}`}
            onClick={() => sendEventRedirect(text)}
            className="flex flex-col border-[1px] rounded-[360px] border-restructure-border-primary placeholder:min-h-[32px] min-w-[32px] min-h-[32px] max-w-[32px] justify-center items-center"
            rel="noreferrer"
          >
            <div className="w-4 h-4">
              <Image
                src={image}
                alt={text}
                width={16}
                height={16}
                className="w-full max-w-full"
              />
            </div>
          </Link>
        )
      })}
    </div>
  )
}
