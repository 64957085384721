import { useContext } from 'react'
import { TenantContext } from 'src/sdk/tenant'

import storeConfig from '../../store.config'

export const useStoreConfig = () => {
  const { tenant } = useContext(TenantContext)

  const configByTenant = storeConfig[tenant]

  return { ...configByTenant }
}
