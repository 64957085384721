import { useContext } from 'react'
import {
  GlobalContext,
  findSectionCMSByName,
} from 'src/components/global/context/global-context'
import type { FooterSection } from 'src/components/global/types/footer-section'
import Logo from 'src/components/ui/Logo'

import { ServicesHelpAboutUs } from './ServicesHelpAboutUs'
import { SocialMedia } from './SocialMedia'
import { useFooter } from './useFooter'
import { SubHeader } from './SubHeader'
import Dropdown from './Dropdown'
import { PaymentMethods } from './PaymentMethods'
import { SafetyTransaction } from './SafetyTransaction'

const Footer = () => {
  const { globalSectionsCMS } = useContext(GlobalContext)

  const { validateProps, sendEventRedirect } = useFooter()

  const footer = findSectionCMSByName(globalSectionsCMS, 'Footer [Global]')

  const propsIsValid = validateProps(footer?.data as FooterSection)

  if (!propsIsValid) {
    return <div data-testid="emptyFooter" />
  }

  const {
    title,
    serviceLinks,
    helpLinks,
    aboutUsLinks,
    deliveryPolicy,
    privacyPolicy,
    copyRight,
    featuredLinks,
    socialLinks,
  } = footer?.data as FooterSection

  return (
    <>
      <footer
        data-testid="footer"
        className="w-full bg-restructure-background-blue py-20 relative mx-auto"
      >
        <div
          className="w-full max-w-[1280px] mx-auto flex flex-col py-8 px-4
          restructure-tablet:py-8 restructure-tablet:px-5
        "
        >
          <div
            data-testid="footerTitle"
            aria-label={`Texto principal do rodapé: ${title}`}
            className="text-restructure-white
          mobile-heading-title2
          tablet-mobile-heading-title2
          desktop-heading-title1
          pb-12
          restructure-small-desktop:pb-[80px]"
          >
            {title}
          </div>

          <div
            className="flex flex-col
          justify-between gap-16
          restructure-small-desktop:flex-row
          pb-12
          restructure-small-desktop:pb-[80px]
         "
          >
            <div className="flex flex-col gap-6 pb-16 w-[230px]">
              <div className="flex flex-col gap-4">
                {featuredLinks.map((featuredLink) => (
                  <SubHeader
                    key={featuredLink.link}
                    title={featuredLink.text}
                    link={featuredLink.link}
                  />
                ))}
              </div>

              <SocialMedia socialLinks={socialLinks} />
            </div>

            <div
              className="flex flex-1 flex-col
          justify-evenly gap-16
          restructure-small-desktop:flex-row
         "
            >
              <ServicesHelpAboutUs
                items={serviceLinks}
                itemSectionTitle="Serviços"
              />
              <ServicesHelpAboutUs items={helpLinks} itemSectionTitle="Ajuda" />
              <ServicesHelpAboutUs
                items={aboutUsLinks}
                itemSectionTitle="Sobre nós"
              />
            </div>
          </div>

          <div
            className="flex flex-col  restructure-small-desktop:flex-row justify-between items-start gap-2
          "
          >
            <div
              aria-label="Logo Decathlon"
              className="hidden restructure-small-desktop:flex p-[4px] gap-[8px]  items-center justify-center"
            >
              <Logo width={193} height={38} bgFill="#fff" />
            </div>

            <PaymentMethods />

            <SafetyTransaction />

            <div
              aria-label="Logo Decathlon"
              className="flex restructure-small-desktop:hidden p-[4px] gap-[8px]   items-center justify-center"
            >
              <Logo width={170} height={47} bgFill="#fff" />
            </div>

            <Dropdown />
          </div>

          <div
            className="flex flex-row gap-6 text-restructure-white underline
          desktop-body-regular-text3-link
          tablet-mobile-body-regular-text3-link
          mobile-body-regular-text3-link
          pt-12
          restructure-small-desktop:pt-14
          "
          >
            <a
              id="footerPrivacyPolicy"
              data-testid="footerPrivacyPolicy"
              onClick={() => {
                sendEventRedirect(privacyPolicy.text)
              }}
              aria-label={`Link de redirecionamento para ${privacyPolicy.text}`}
              href={privacyPolicy.link}
            >
              {privacyPolicy.text}
            </a>
            <a
              data-testid="footerDeliveryPolicy"
              onClick={() => sendEventRedirect(deliveryPolicy.text)}
              aria-label={`Link de redirecionamento para ${deliveryPolicy.text}`}
              href={deliveryPolicy.link}
            >
              {deliveryPolicy.text}
            </a>
          </div>

          <div
            aria-label={`Texto de copy right do site, ${copyRight}`}
            className="flex flex-row text-restructure-white
          desktop-caption-regular
          tablet-caption-regular
          mobile-caption-regular
          pt-[24px]"
          >
            {copyRight}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
