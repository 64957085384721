export const getCookie = (cname: string) => {
  const isBrowser = typeof window !== `undefined`

  if (!isBrowser) {
    return ''
  }

  const name = `${cname}=`
  const ca = document.cookie.split(';')

  for (const element of ca) {
    let c = element

    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export const readCookie = (cookie: string): boolean => {
  if (typeof document !== 'undefined') {
    return document.cookie.split('; ').includes(cookie)
  }

  return false
}

export const setCookie = (
  cookie: string,
  value: string,
  days: number
): void => {
  const date = new Date()

  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`

  if (typeof document !== 'undefined') {
    document.cookie = `${cookie}=${value};${expires};path=/`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.dktlab.com.br`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.decathlon.com.br`
    document.cookie = `${cookie}=${value};${expires};path=/;domain=.sfj-5066a91--decathlonstore.preview.vtex.app`
  }
}

export const getCookieValue = (
  pattern: string
): Record<string, unknown> | undefined => {
  if (typeof document !== 'undefined') {
    const result = document.cookie
      .split('; ')
      .filter((item) => item.includes(pattern))

    const result2 = result.map((item) => {
      return item.split('=')
    })

    return Object.fromEntries(result2)
  }

  return undefined
}

export const getPersonalToken = (arrayCookies: string[]): string => {
  if (
    arrayCookies.filter((item) => item.includes('memberAuthToken')).length === 0
  ) {
    return ''
  }

  return arrayCookies
    .filter((item) => item.includes('memberAuthToken'))[0]
    .split('=')[1]
}
