/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import useStorage from 'src/sdk/hooks/useStorage'
import { useLinxAccountView } from 'src/sdk/linx/hooks/useLinxAccountView'

import { getAuthToken } from './utils'
import type { Addresses, UserDecathlon } from '../types'

type AccountProviderProps = {
  children: ReactNode
}

type AccountContextProps = {
  isLoading: boolean
  userDecathlon: UserDecathlon | null
  memberAuthToken: string | null
  memberUnauthorized: boolean
  setAlertSuccess: (item: boolean) => void
  updateUserDecathlon: () => Promise<void>
  alertSuccess: boolean
  setAlertError: (item: boolean) => void
  alertError: boolean
  cepValidation: boolean
  setCepValidation: (item: boolean) => void
  addressSelected: Addresses | undefined
  setAddressSelected: (item: Addresses | undefined) => void
  actionForm: any
  setActionForm: any
}

export const AccountContext = createContext<AccountContextProps>(
  {} as AccountContextProps
)

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { storageGetItem, storageSetItem } = useStorage()
  const { sendAccountViewEvent } = useLinxAccountView()

  const [isLoading, setIsLoading] = useState(false)
  const [userDecathlon, setUserDecathlon] = useState<UserDecathlon | null>(null)
  const [memberAuthToken, setMemberAuthToken] = useState<string | null>(null)
  const [memberUnauthorized, setMemberUnauthorized] = useState<boolean>(false)

  const [alertError, setAlertError] = useState(false)
  const [alertSuccess, setAlertSuccess] = useState(false)

  const [addressSelected, setAddressSelected] = useState<
    Addresses | undefined
  >()

  const [actionForm, setActionForm] = useState(true)
  const [cepValidation, setCepValidation] = useState(false)

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    sendAccountViewEvent()
  }, [sendAccountViewEvent, userDecathlon])

  useEffect(() => {
    getAuthToken().then((authToken) => {
      if (!authToken) {
        setMemberUnauthorized(true)

        return 
      }

      return setMemberAuthToken(authToken)
    })
  }, [])

  useEffect(() => {
    const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

    if (storageUserDecathlon) {
      return setUserDecathlon(JSON.parse(storageUserDecathlon))
    }

    if (!memberAuthToken) {
      return
    }

    setIsLoading(true)

    const getUserDecathlon = async () => {
      const { data } = await axios.post('/api/account/getUserDecathlon', {
        memberAuthToken,
      })

      setIsLoading(false)
      setUserDecathlon(data)
      storageSetItem('user-decathlon', JSON.stringify(data), 'session')
    }

    getUserDecathlon()
  }, [memberAuthToken, storageGetItem, storageSetItem])

  const updateUserDecathlon = async () => {
    const { data } = await axios.post('/api/account/getUserDecathlon', {
      memberAuthToken,
    })

    setUserDecathlon(data)
    storageSetItem('user-decathlon', JSON.stringify(data), 'session')
  }

  return (
    <AccountContext.Provider
      value={{
        isLoading,
        userDecathlon,
        memberAuthToken,
        memberUnauthorized,
        setAlertSuccess,
        alertSuccess,
        alertError,
        setAlertError,
        setCepValidation,
        cepValidation,
        addressSelected,
        setAddressSelected,
        actionForm,
        setActionForm,
        updateUserDecathlon,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccountContext = () => {
  return useContext(AccountContext)
}
