/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useStorage from 'src/sdk/hooks/useStorage'
import { getCookie } from 'src/utils/getCookie'

import type { LinxMetaUser, UserDecathlon, LinxMeta } from '../types'

export const useLinxUser = () => {
  const { storageGetItem, storageSetItem } = useStorage()

  const getIdGeneratedByLinx = useCallback(() => {
    const browserId = getCookie('chaordic_browserId')

    return browserId === '' ? undefined : browserId
  }, [])

  const getDeviceId = useCallback(() => {
    const linxDeviceId =
      getIdGeneratedByLinx() ?? storageGetItem('linx-deviceid')

    if (linxDeviceId) {
      return linxDeviceId
    }

    const UUID = uuidv4()

    storageSetItem('linx-deviceid', UUID)

    return UUID
  }, [getIdGeneratedByLinx, storageGetItem, storageSetItem])

  const getLinxUser = useCallback(() => {
    const userDecathlon: UserDecathlon = JSON.parse(
      storageGetItem('user-decathlon') ?? null
    )

    if (!userDecathlon) {
      return {
        loggedIn: false,
        deviceId: getDeviceId(),
      }
    }

    const email = userDecathlon?.getUserProfile?.claims?.email
    const given_name = userDecathlon?.getUserProfile?.claims?.given_name
    const birthdate = userDecathlon?.getUserProfile?.claims?.birthdate
    const gender = userDecathlon?.getUserProfile?.claims?.gender

    const allowMailMarketing =
      userDecathlon?.getConsent?.events?.[0]?.consents?.purposes?.find(
        ({ id }: { id: string }) => id === 'mysports-RmzeVX4K'
      )?.enabled ?? false

    const userLinx = {
      loggedIn: true,
      email,
      id: email,
      name: given_name,
      allowMailMarketing,
      birthday: birthdate,
      gender,
      deviceId: getDeviceId(),
    }

    return userLinx
  }, [getDeviceId, storageGetItem])

  const addUserIfLogged = useCallback(
    (linxMeta: LinxMeta) => {
      const userDecathlon: UserDecathlon = JSON.parse(
        storageGetItem('user-decathlon') ?? null
      )

      if (!userDecathlon) {
        return linxMeta
      }

      const { email, given_name, birthdate, gender } =
        userDecathlon?.getUserProfile?.claims

      const allowMailMarketing =
        userDecathlon.getConsent?.events?.[0]?.consents?.purposes?.find(
          ({ id }: { id: string }) => id === 'mysports-RmzeVX4K'
        )?.enabled ?? false

      const linxMetaUser: LinxMetaUser = {
        email,
        id: email,
        name: given_name,
        allowMailMarketing,
        birthday: birthdate,
        gender: gender === 'male' ? 'M' : 'F',
      }

      if (!['male', 'female'].includes(gender)) {
        delete linxMetaUser.gender
      }

      return { ...linxMeta, user: linxMetaUser }
    },
    [storageGetItem]
  )

  return { addUserIfLogged, getLinxUser }
}
