/* eslint-disable vtex/prefer-early-return */
import { useEffect, useState } from 'react'
import { readCookie, setCookie, getCookieValue } from 'src/utils/getCookie'
import CloseButton from 'src/components/ClubComponents/CloseButton'
import Icon from 'src/components/ui/Icon/Icon'
import './styles.scss'

type ButtonsAppsProps = 'install-app' | 'open-app'

type ActionAppsProps = 'Sim' | 'Não' | 'X'

const DECATHLON_APP_OFFERS = [
  'Válido apenas para a primeira compra do CPF',
  'Para compras acima de R$ 149,99, excluindo bicicletas',
  'Apenas sobre o valor dos produtos, não incluindo o valor do frete',
  'Exclusivo para produtos vendidos e entregues por Decathlon',
]

const PopUpAppsflyer = () => {
  const [isAppRefused, setIsAppRefused] = useState<boolean>(
    readCookie('refuseApp=true')
  )

  const [modalOpenApp, setModalOpenApp] = useState<boolean>(
    !readCookie('appInstalled=true')
  )

  const [isAppInstalled, setIsAppInstalled] = useState<boolean>(
    readCookie('appInstalled=true')
  )

  useEffect(() => {
    if (typeof window === 'undefined' && typeof document !== 'object') {
      return
    }

    window.dataLayer = window.dataLayer || []

    if (document.querySelector('.install-app')?.classList.contains('flex')) {
      const obj = {
        event: 'ga_custom_event',
        event_category: 'Popup APP',
        event_action:
          '(Resumo: APP Decathlon não encontrado. Deseja instalar?)',
        event_label: 'Banner exibido',
        event_is_user_interaction: false,
      }

      window.dataLayer.push(obj)

      return
    }

    if (document.querySelector('.open-app')?.classList.contains('flex')) {
      const obj = {
        event: 'ga_custom_event',
        event_category: 'Popup APP',
        event_action: '(Resumo: APP Decathlon encontrado. Deseja abrir?)',
        event_label: 'Banner exibido',
        event_is_user_interaction: false,
      }

      window.dataLayer.push(obj)
    }
  })

  const handleSendEvent = (
    action: ActionAppsProps,
    modal: ButtonsAppsProps
  ): void => {
    const obj = {
      event: 'ga_custom_event',
      event_category: 'Popup APP',
      event_action:
        modal === 'install-app'
          ? '(Resumo: APP Decathlon não encontrado. Deseja instalar?)'
          : '(Resumo: APP Decathlon encontrado. Deseja abrir?)',
      event_label: `Interacao: ${action}`,
      event_is_user_interaction: true,
    }

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(obj)
    }
  }

  const handleCloseApp = (app: ButtonsAppsProps): void => {
    if (app === 'install-app') {
      setIsAppInstalled(true)

      return
    }

    setModalOpenApp(true)
  }

  const handleClickYes = (action: ButtonsAppsProps): void | undefined => {
    if (typeof window === 'undefined') {
      return undefined
    }

    if (action === 'install-app') {
      setCookie('appInstalled', 'true', 365)
      const utmCookies = getCookieValue('utm_')

      window.location.href = `https://decathlon.onelink.me/DWvD/?pid=web_acquisition&af_channel=${utmCookies?.utm_source}&c=${utmCookies?.utm_campaign}&af_ad=${utmCookies?.utm_medium}&af_adset=${utmCookies?.utm_term}&af_sub1=${utmCookies?.utm_content}`
      setIsAppInstalled(true)

      return
    }

    let uriStructure = ''

    if (window.location.pathname === '/') {
      uriStructure = 'decathlon://home'
    }

    if (window.location.pathname.includes('/p', 9)) {
      const productDetails = window.dataLayer.find(
        (item) => item.event === 'productDetail'
      )

      const productId = productDetails.ecommerce.detail.products[0].id

      uriStructure = `decathlon://pdp?product-id=${productId}`
    }

    if (window.location.pathname.includes('/pesquisa')) {
      const urlSearchParams = new URLSearchParams(window.location.search)
        .get('q')
        ?.replaceAll(' ', '-')

      uriStructure = `decathlon://search?term=${urlSearchParams}`
    }

    const utmCookies = getCookieValue('utm_')

    window.location.href = `https://decathlon.onelink.me/DWvD/?pid=web_traffic&deep_link_value=${uriStructure}&af_channel=${utmCookies?.utm_source}&c=${utmCookies?.utm_campaign}&af_ad=${utmCookies?.utm_medium}&af_adset=${utmCookies?.utm_term}&af_sub1=${utmCookies?.utm_content}`
    setModalOpenApp(true)
  }

  const handleClickNo = (): void => {
    setCookie('refuseApp', 'true', 15)
    setIsAppRefused(true)
  }

  return (
    <div className="modals-appsflyer">
      <div
        className={`modals-appsflyer__modal open-app ${
          modalOpenApp || isAppRefused ? 'hidden' : 'flex'
        }`}
      >
        <button
          className="modals-appsflyer__modal--closeBtn"
          aria-label="close modal"
          onClick={() => {
            handleSendEvent('X', 'open-app')
            handleCloseApp('open-app')
          }}
        >
          <CloseButton size={20} thickness={2} color="#0082C3" left="97%" />
        </button>
        <h2 className="modals-appsflyer__modal--title">
          Gostaria de ser redirecionado para o app para uma melhor experiência?
        </h2>
        <div className="modals-appsflyer__modal--btns">
          <button
            className="modals-appsflyer__modal--btn button-yes"
            onClick={() => {
              handleSendEvent('Sim', 'open-app')
              handleClickYes('open-app')
            }}
          >
            Sim
          </button>
          <button
            className="modals-appsflyer__modal--btn button-no"
            onClick={() => {
              handleSendEvent('Não', 'open-app')
              handleClickNo()
            }}
          >
            Não
          </button>
        </div>
      </div>
      <div
        className={`modals-appsflyer__modal install-app ${
          isAppInstalled || isAppRefused ? 'hidden' : 'flex'
        }`}
      >
        <button
          className="modals-appsflyer__modal--closeBtn"
          aria-label="close modal"
          onClick={() => {
            handleSendEvent('X', 'install-app')
            handleCloseApp('install-app')
          }}
        >
          <CloseButton size={20} thickness={2} color="#0082C3" left="97%" />
        </button>
        <h2 className="modals-appsflyer__modal--title font-bold">
          Aproveite 10% de desconto na primeira compra com o app Decathlon!
        </h2>
        {DECATHLON_APP_OFFERS.map((offer) => (
          <p
            key={offer}
            className="modals-appsflyer__modal--text flex flex-row"
          >
            <Icon name="Check" width={24} height={24} />
            {offer}
          </p>
        ))}

        <div className="modals-appsflyer__modal--btns">
          <button
            className="button-yes"
            onClick={() => {
              handleSendEvent('Sim', 'install-app')
              handleClickYes('install-app')
            }}
          >
            Quero 10% de desconto
          </button>
          <button
            className="button-no"
            onClick={() => {
              handleSendEvent('Não', 'install-app')
              handleClickNo()
            }}
          >
            Não preciso de desconto
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopUpAppsflyer
