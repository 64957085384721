import { Link } from 'gatsby'
import type { EventTrackParams } from 'src/utils/restructure/analytics/types/eventTrack'

import LogOut from './LogOut'

type LoggedInContentProps = {
  location: string
  trackEvent: ({ eventAction, eventPage }: EventTrackParams) => void
}

const loggedLinks = [
  {
    text: 'Minha conta',
    url: '/account/',
    areaLabel: 'Ir para minha conta',
  },
  {
    text: 'Meus pedidos',
    url: '/account/orders',
    areaLabel: 'Ir para meus pedidos',
  },
  {
    text: 'Clube Decathlon',
    url: '/clube',
    areaLabel: 'Ir para clube decathlon',
  },
]

const LoggedInContent = ({ location, trackEvent }: LoggedInContentProps) => {
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    trackEvent({
      eventAction: event.currentTarget.ariaLabel!,
      eventPage: location,
    })
  }

  return (
    <div>
      <ul className="flex flex-col gap-3">
        {loggedLinks.map((links) => {
          return (
            <li
              className="text-sm font-normal font-inter hover:font-semibold"
              key={links.url}
            >
              <Link
                aria-label={links.areaLabel}
                to={links.url}
                onClick={(event) => handleClick(event)}
              >
                {links.text}
              </Link>
            </li>
          )
        })}
      </ul>
      <div className="block w-full h-px bg-neutral04 my-3" />

      <LogOut location={location} trackEvent={trackEvent} className="text-sm" />
    </div>
  )
}

export default LoggedInContent
