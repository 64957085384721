import { Link } from 'gatsby'
import Icon from 'src/components/ui/Icon'
import { useState } from 'react'
import { Image } from 'src/components/ui/Image'
import type {
  PossibleThirdLevelLayout,
  Level1,
  SubcategoryBasedOnType,
} from 'src/graphql/queries/menu'
import { stripUrl } from 'src/utils/stripUrl'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import DropdownContentVariations from './ContentVariations'
import { ChevronBlue } from '../HeaderMobile/components/utils/icons'

interface DropwdowMenu extends Level1 {
  variant?: 'default' | 'second'
  positionRight?: boolean
  setInHovering: React.Dispatch<React.SetStateAction<boolean>>
}

type ThirdLevel = Array<
  [
    string | undefined,
    {
      typeThirtLevel: PossibleThirdLevelLayout
      subcategories: SubcategoryBasedOnType[]
    }
  ]
>

export function urlToImage(image: string) {
  return image.includes('empty')
    ? `https://decathlonstore.vteximg.com.br${image}`
    : image
}

function DropdownMenu({
  url,
  title,
  categories = [],
  imageBannerDesktop,
  linkBanner,
  new_tabBanner,
  variant = 'default',
  positionRight = false,
  setInHovering,
}: DropwdowMenu) {
  const thirdLevelLinks: ThirdLevel =
    categories?.map((item) => [
      item.title || '',
      {
        typeThirtLevel: item.typeThirtLevel,
        subcategories: item.subcategories ?? [],
      },
    ]) || []

  const firstThirdLevelID =
    thirdLevelLinks.length > 0 ? `${title} | ${thirdLevelLinks[0][0]}` : ''

  const [currentThirdLevelSelected, setCurrentThirdLevelSelected] =
    useState(firstThirdLevelID)

  const [currentSecondLevelURL, setCurrentSecondLevelURL] = useState(url)

  const customStyles = {
    container: 'w-full',
    contentNav: 'max-w-[291px]',
    containerDropdown: 'w-full',
    contentDropdown: 'columns-5 columns-to-count',
    wrapperDropdown: 'py-8',
    contentTitleDropdown: 'hidden',
    contentItemDropdownList: 'mt-2',
    containerImageDesktop: 'hidden lg:block',
    imageDesktopHeight: 400,
  }

  const defaultStyles = {
    container: '',
    contentNav: '',
    containerDropdown: 'w-[63.75%]',
    contentDropdown: 'columns-2 mt-4',
    wrapperDropdown: 'py-8',
    contentTitleDropdown: '',
    contentItemDropdownList: 'mt-2',
    containerImageDesktop: '',
    imageDesktopHeight: 360,
  }

  const menuStyles = variant === 'default' ? defaultStyles : customStyles

  menuStyles.container += positionRight
    ? ' right-0'
    : ' left-1/2 -translate-x-1/2'

  const handleSendEvent = (position: string, event: string | undefined) => {
    const eventTrack = makeEventTrack({
      eventAction: `${event}${position}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
  }

  return (
    <section
      data-testid="section-dropdown"
      className={`border-t border-gray absolute top-[47px] left-0 ${menuStyles.container} dropdown-content w-screen max-h-[70vh]  pointer-events-none -z-10 opacity-0 transition-all duration-300 flex group-hover:z-10 group-hover:opacity-100 group-hover:pointer-events-auto absolute bg-white rounded-b-[4px] shadow-[0px 12px 12px 0px]`}
      style={{ boxShadow: '0px 12px 12px 0px #00537D1A' }}
      onMouseLeave={() => setCurrentThirdLevelSelected(firstThirdLevelID)}
    >
      <div className="flex w-full">
        <article className="flex-1 flex">
          <ul
            className={`min-w-[316px] bg-restructure-background-secondary pt-5 px-4 max-h-full overflow-y-scroll w-[36.25%] text-left scrollbar-v2 ${customStyles.contentNav}`}
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#C3C9CF #F7F8F9', // Define a cor do polegar e do fundo
            }}
          >
            {categories.map((item) => {
              const titleId = `${title} | ${item.title}`
              const classLink =
                currentThirdLevelSelected === titleId
                  ? '!text-restructure-action '
                  : 'text-restructure-secondary'

              const classIcon =
                currentThirdLevelSelected === titleId
                  ? '!text-restructure-action '
                  : 'text-restructure-secondary'

              return (
                <li
                  key={titleId}
                  data-target={titleId}
                  onMouseEnter={() => {
                    setCurrentThirdLevelSelected(titleId)
                    setCurrentSecondLevelURL(item.url)
                  }}
                  className="py-3 group group/linkmenu"
                  data-testid="item-category"
                >
                  <Link
                    to={item.url ?? ''}
                    className={`desktop-body-regular-text1 text-left group group-hover/linkmenu:text-restructure-action  ${classLink} flex justify-between items-center`}
                    aria-label={item.title}
                    title={item.title}
                    onClick={() => setInHovering(false)}
                  >
                    {item.title}
                    <Icon
                      name="CaretRight"
                      width={16}
                      height={16}
                      className={`${classIcon} group-hover/linkmenu:text-restructure-action `}
                    />
                  </Link>
                </li>
              )
            })}
          </ul>
          <div className={`${menuStyles.containerDropdown} relative `}>
            {thirdLevelLinks.map(([keyTitle, propsSubcategories]) => {
              const titleId = `${title} | ${keyTitle}`
              const isActive =
                currentThirdLevelSelected === titleId ? 'z-10' : ''

              return (
                <div
                  className={`overflow-y-scroll scrollbar-v2 w-full h-full bg-white flex flex-col px-12 ${menuStyles.wrapperDropdown} bg-white absolute left-0 top-0 items-start ${isActive}`}
                  key={keyTitle}
                  data-content={titleId}
                >
                  <div className="flex gap-4 items-center mb-6">
                    <div
                      data-testid="title-category"
                      className="desktop-heading-title5 text-restructure-secondary"
                    >
                      {currentThirdLevelSelected.split('|')[1]}
                    </div>
                    <Link
                      to={currentSecondLevelURL ?? ''}
                      className="flex items-center gap-2 desktop-body-semibold-text3 text-restructure-action bg-restructure-background-secondary rounded-round px-4 py-1 hover:bg-restructure-background-tertiary active:bg-restructure-background-neutral-4 disabled:opacity-80"
                      aria-label={titleId}
                      title={titleId}
                      data-testid="item-see-all"
                      onClick={() => {
                        handleSendEvent(
                          ' > Ver tudo - Menu',
                          `${title} > ${titleId.split('|')[1]}`
                        )
                        setInHovering(false)
                      }}
                    >
                      Ver tudo
                      <ChevronBlue />
                    </Link>
                  </div>
                  <DropdownContentVariations
                    {...propsSubcategories}
                    titleId={keyTitle}
                    menuStyles={menuStyles}
                    department={title ?? ''}
                    data-testid="item-list"
                    setInHovering={setInHovering}
                  />
                </div>
              )
            })}
          </div>
        </article>
        {!imageBannerDesktop.includes('empty') && (
          <article className={menuStyles.containerImageDesktop}>
            <a
              href={linkBanner}
              target={new_tabBanner ? '_blank' : '_top'}
              rel="noreferrer"
            >
              <Image
                src={stripUrl(urlToImage(imageBannerDesktop))}
                alt={`Banner Menu - ${title}`}
                width={280}
                height={menuStyles.imageDesktopHeight}
                loading="lazy"
                className={
                  imageBannerDesktop.includes('empty')
                    ? 'opacity-0'
                    : 'opacity-100'
                }
              />
            </a>
          </article>
        )}
      </div>
    </section>
  )
}

export default DropdownMenu
