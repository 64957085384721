import type {
  ProductItem,
  Product,
} from 'src/components/restructure/product/sections/ShelfBoughtTogether/utils/types'

import { makeProductShowcase } from './makeProductShowcase'
import type { AddToCartEvent } from './types/addToCartEvent'

export const makeAddToCartBuyTogetherEvent = (
  items: Array<{ product: Product; productItem: ProductItem }>
): AddToCartEvent => {
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: items.map((item) =>
          makeProductShowcase(item.product, item.productItem, 'Compre junto')
        ),
      },
    },
  }
}
