/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { memo } from 'react'

import { useImage } from './useImage'
import type { ImageOptions } from './useImage'

interface Props extends ImageOptions {
  preload?: boolean
}

function Image({ preload = false, className, ...otherProps }: Props) {
  const imgProps = useImage(otherProps)
  const { src, srcSet } = imgProps

  return (
    <>
      <img
        data-store-image
        {...imgProps}
        alt={imgProps.alt}
        className={className}
        data-src={src}
        data-srcset={srcSet}
      />
    </>
  )
}

Image.displayName = 'Image'
export default memo(Image)
