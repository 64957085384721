import { Link } from 'gatsby'
import { redirectToAccountUrl } from 'src/utils/redirectToAccountUrl'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import type { EventTrackParams } from 'src/utils/restructure/analytics/types/eventTrack'

type LoggedOutContentProps = {
  location: string
  trackEvent: ({ eventAction, eventPage }: EventTrackParams) => void
}

const LoggedOutContent = ({ location, trackEvent }: LoggedOutContentProps) => {
  const isB2B = checkEnviromentIsB2B()

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    trackEvent({
      eventAction: event.currentTarget.ariaLabel!,
      eventPage: location,
    })
  }

  return (
    <div className="flex gap-6 flex-col font-normal font-inter">
      <p className="text-sm">
        Faça login para consultar o seu cashback e acompanhar seu pedido :)
      </p>

      <Link
        to={redirectToAccountUrl(isB2B)}
        aria-label="Fazer login"
        className="w-full min-w-[182px] flex items-center justify-center h-8 bg-black text-white font-semibold font-inter text-sm rounded-round"
        onClick={(event) => handleClick(event)}
      >
        Fazer login
      </Link>
    </div>
  )
}

export default LoggedOutContent
