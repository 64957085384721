module.exports = {
  B2B: {
    tenant: 'B2B',

    search: {
      pesquisa: {
        engine: 'vtex',
        salesChannel: 1,
      },
      category: {
        engine: 'vtex',
        salesChannel: 1,
      },
      colecao: {
        engine: 'linx',
        salesChannel: 1,
      },
      collection: {
        engine: 'vtex',
        salesChannel: 1,
      },
      recommendation: {
        engine: 'linx',
        salesChannel: 1,
      },
      input: {
        engine: 'vtex',
        salesChannel: 1,
      },
    },

    storeUrl: 'https://luxury-gaufre-f50459.netlify.app',
    checkoutUrl: 'https://secure.decathlonpro.com.br/checkout',
    loginUrl: 'https://secure.decathlonpro.com.br/login',
    signUpUrl: 'https://secure.decathlonpro.com.br/login',
    accountUrl: 'https://secure.decathlonpro.com.br/_secure/account/#/profile',
  },

  B2C: {
    tenant: 'B2C',

    search: {
      pesquisa: {
        engine: 'linx',
        salesChannel: 3,
      },
      category: {
        engine: 'vtex',
        salesChannel: 3,
      },
      colecao: {
        engine: 'linx',
        salesChannel: 3,
      },
      collection: {
        engine: 'vtex',
        salesChannel: 3,
      },
      recommendation: {
        engine: 'linx',
        salesChannel: 3,
      },
      input: {
        engine: 'linx',
        salesChannel: 3,
      },
    },

    storeUrl: 'https://store.decathlon.com.br',
    checkoutUrl: 'https://secure.decathlon.com.br/checkout',
    loginUrl: 'https://secure.decathlon.com.br/login',
    signUpUrl: 'https://secure.decathlon.com.br/cadastro',
    accountUrl: 'https://www.decathlon.com.br/account',
  },

  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: 'decathlonstore',
    workspace: 'cms',
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  proxyUrl: {
    store: 'decathlonstore.vtexcommercestable',
  },

  // Default channel
  channel: '{"salesChannel":"1"}',
  locale: 'pt-BR',

  // Production URLs
  storeUrl: 'https://www.decathlonpro.com.br',
  secureSubdomain: 'https://secure.decathlon.com.br',
  checkoutUrl: 'https://secure.decathlon.com.br/checkout',
  loginUrl: 'https://secure.decathlon.com.br/api/io/login',
  signUpUrl: 'https://secure.decathlon.com.br/cadastro',
  accountUrl: 'https://store.decathlon.com.br/account',
  apiReviewDecathlon:
    'https://decathlonstore.myvtex.com/api/io/_v/graphql/private/v1',
  faststoreApi: 'https://decathlonstore.vtex.app/api',

  // Search URLs
  decathlonSearch: 'https://decathlonstore.myvtex.com/_v/api/decathlon-search',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      pdp: '/meia-termica-cano-alto-adulto-de-ski-e-snowboard-638256727/p',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      pages: {
        home: '/',
        pdp: '/meia-termica-cano-alto-adulto-de-ski-e-snowboard-638256727/p',
        collection: '/ciclismo/',
        search: '/pesquisa?q=tenis&sort=score_desc&page=0',
      },
    },
  },
  // @todo deixar dinamico tag gtm B2C - 'GTM-T777CVM'
  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-MJMQ95DX',
  },

  token: {
    storeId: 'decathlonstoreapp',
  },

  logRocket: {
    appId: 'izcqja/corebiz-decathlon',
  },
}
