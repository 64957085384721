import type { ImgHTMLAttributes } from 'react'
import { useMemo } from 'react'

import type { ThumborOptions } from './thumborUrlBuilder'
import { urlBuilder } from './thumborUrlBuilder'

export interface ImageOptions extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  width?: any
  height?: any
  options?: ThumborOptions
  fetchpriority?: 'high' | 'low' | 'auto'
  ref?: React.MutableRefObject<HTMLImageElement | null>
}

const FACTORS = [1, 2]

export const useImage = ({
  src: baseUrl,
  width,
  height,
  options = {},

  ...rest
}: ImageOptions): ImgHTMLAttributes<HTMLImageElement> => {
  const { srcSet, src } = useMemo(() => {
    const builder = urlBuilder(baseUrl, options)

    const srcs = FACTORS.map((factor) => {
      const rescaledWidth = width * factor

      return `${builder(rescaledWidth, height * factor)} ${rescaledWidth}w`
    })

    return {
      src: builder(width * 1, height * 1),
      srcSet: srcs.join(', '),
    }
  }, [height, options, baseUrl, width])

  return {
    src,
    srcSet,
    width: `${width}px`,
    height: `${height}px`,
    ...rest,
  }
}
